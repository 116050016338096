.info {
  display: block;
  line-height: 1.2;
  color: var(--color-primary-500);
  font-size: var(--fs-12);
}

.smallInput {
  width: 210px;
}

.tinySelect {
  width: 110px;
}

.selectWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}