@import '../../styles/responsive.scss';

.container {
  display: flex;
  margin: -50px;
}

.content {
  height: calc(100vh - 76px); // 76px is the height of the header
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 40px;
  width: calc(100% - 210px); // 210px is the width of the menu
}

.title {
  font-size: var(--fs-14);
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--color-primary-500);
}

.confirmModalInfo {
  text-align: left;
  line-height: 1.25;
}

@include laptop {
  .container {
    margin: -30px;
  }

  .content {
    padding: 20px 30px;
  }
}

@include tablet {
  .container {
    margin: -20px;
    margin-bottom: -150px;
  }

  .content {
    padding-bottom: 150px;
  }
}

@include mobile {
  .content {
    width: 100%;
    padding: 20px;
    padding-bottom: 150px;
  }

  .title {
    margin: -20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: var(--color-primary-100);
  }
}