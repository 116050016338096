@import '../../../styles//responsive.scss';

.container {
  background-color: var(--color-white);
  height: calc(100vh - 76px); // 76px is the height of the header
  width: 210px;
  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.60);

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;

    overflow-y: auto;
    overflow-x: hidden;
  
    @supports selector(::-webkit-scrollbar) {
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary-200);
        border-radius: 45px;
      }
    }
    @supports not selector(::-webkit-scrollbar) {
      scrollbar-width: thin;
      scrollbar-color: var(--color-primary-200) transparent;
    }
    
    li {
      a {
        color: var(--color-primary-500);
        padding: 15px 20px;
        text-decoration: none;
        font-weight: 500;
        display: flex;
        align-items: flex-start;
        gap: 8px;
        background-color: var(--color-white);
        border-bottom: 1px solid var(--color-primary-200);
        transition: all 0.1s;

        &:hover {
          background-color: var(--color-primary-100);
        }

        .listItemLabel {
          font-size: var(--fs-12);
          line-height: 1.1;
        }

        .listItemIcon {
          margin-top: 2px;
        }

        .listItemLabelTitle {
          font-size: var(--fs-14);
          color: var(--color-black);
          font-weight: 600;
          display: block;
          line-height: 1.66;
          margin-bottom: 2px;
        }

        &:global(.active) {
          background-color: var(--color-secondary-100);
        }
      }
    }
  }
}

@include mobile {
  .container {
    $offsetTop: calc(76px + 64px); // 76px is the height of the header, 64px is the height of the burger
    position: fixed;
    z-index: 1;
    top: $offsetTop;
    height: calc(100vh - $offsetTop);
    transform: translateX(-100%);
    transition: transform 0.3s;
    width: 100%;
    box-shadow: none;

    &.open {
      transform: translateX(0);
    }
  }
}