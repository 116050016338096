.submitBtn {
    margin: 1.5rem 0;

    button {
        margin: auto;
    }
}

.errorModalSubtitle {
    max-width: 400px;
}

.requiredDocumentsPhysical * {
    font-size: var(--fs-16) !important;
}