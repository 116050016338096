.forgotPasswordTitle {
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.noAccount {
    margin-top: 1rem;
    text-align: center;
    font-size: 12.8px;

    a {
        margin-left: 0.45rem;
    }
}

.userAction {
    margin-top: 2rem;
}

.submit {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.cancel {
    font-size: 12.8px;
    color: var(--color-secondary);
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.dateInput {
    max-width: 200px;
}

.wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.form {
    width: 100%;
    max-width: 460px;
}

.caracteristicsForm {
    width: 100%;

    .formInput {
        max-width: 371px;
    }
}

.formTitleWidth {
    text-wrap: balance;
}

.message {
    min-width: 160px;
}

.modal {
    min-height: 500px;
}

.documentTypeValue {
    font-size: var(--fs-13);
    color: var(--color-primary-500);
}

.documentNameValue {
    font-size: var(--fs-13);
    font-weight: 700;
    cursor: pointer;
    display: initial;

    &:hover {
        text-decoration: underline;
    }
}

.needOwner {
    display: flex;
    gap: 20px;
    align-items: center;
}

.feesInfo {
    display: flex;
    gap: 5px;
    // align-items: flex-start;
}

.radioGroupResidenceDelete {
    align-items: center;
}

.deleteResidenceButtonContainer, .propertyToResidenceButtonContainer {
    display: flex;
    gap: 1rem;
    > * {
        flex: 1;
    }
}

.propertiesList {
    width: 100%;
}

.nameAddress {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.propertyName {
    display: flex;
    color: var(--color-secondary);
    font-weight: 700;
    text-decoration: underline;
}

.externalLink {
    margin-left: .3rem;
}

.seeMore {
    display: flex;
    align-items: center;

    svg {
        cursor: pointer
    }
}

.propertyToResidenceForm {
    text-align: left;
    min-height: 200px;

    .desc {
        text-align: center;
    }
}

.propertiesThatCannotBeAddedList {
    margin-left: 1rem;

    * {
        color: inherit !important;
    }
}