@import "../../../styles/responsive.scss";

.impersonated {
    text-align: left;
}
.wrapper {
    display: flex;
}

.menu {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 76px;
    bottom: 0;
    width: 206px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 20px 0;
    padding: 0 0 20px;
    overflow-y: auto;
    background-color: var(--color-primary);
    color: var(--color-white);
    font-size: 12.8px;

    overflow-x: hidden;

    @supports selector(::-webkit-scrollbar) {
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-white);
            border-radius: 45px;
            border: 3px solid var(--color-primary);
    }
    }
    @supports not selector(::-webkit-scrollbar) {
        scrollbar-width: thin;
        scrollbar-color: var(--color-white) transparent;
    }
}

.menuSection {
    margin-bottom: 40px;
}

.menuHeading {
    font-weight: 700;
    margin-bottom: 15px;
    padding: 0 20px;
}

.menuList {
    list-style-type: none;
}

.menuItem {
    position: relative;
    padding: 0 20px;
    &.active {
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 8px;
            width: 4px;
            height: 26px;
            border-radius: 0px 3px 3px 0px;
            background-color: var(--color-tertiary);
        }
    }

    .menuIcon {
        margin-right: 10px;
    }

    .menuLabel {
        position: relative;

        svg {
            position: absolute;
            bottom: -7px;
            left: 0;
        }
    }

    a, button {
        display: flex;
        align-items: center;
        padding: 9px 0;
        color: var(--color-white);
        text-decoration: none;
        font-weight: 500;
    }
}

.logoutContent {
    display: flex;
    gap: 10px;
    // align-items: center;
    text-align: start;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 100%;
}

.contentWrap {
    background-color: var(--color-primary);
    padding-left: 206px;
    width: 100%;

    .content {
        border-radius: 40px 0px 0px 0px;
        background-color: var(--color-white);
        padding: 50px;

        height: calc(100vh - 76px);
        overflow-y: auto;
        position: relative;
    }

    &.noMenu {
        padding-left: 0;
    }
}

.burger {
    .burgerMenu {
        position: fixed;
        z-index: 9;
        transform: translateX(-100%);
        left: 0;
        top: 0;
        bottom: 0;
        padding-top: 6rem;
        width: 450px;
        max-width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: transform 0.3s ease-in-out;
        background-color: var(--color-primary);
        color: var(--color-white);
        font-size: var(--fs-18);
        overflow-y: auto;

        &.open {
            transform: translateX(0);
        }

        &.impersonated {
            padding-bottom: 100px;
        }

        .logout {
            padding-bottom: 2rem;
        }
    }
    .burgerContent {
        padding: 20px 20px 100px;
        position: relative;
        overflow-y: auto;
        height: calc(100vh - 76px);
        background-color: var(--color-white);

        &.open {
            &:before {
                content: "";
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                min-height: 100vh;
                background-color: rgba(0, 0, 0, 0.361);
                z-index: 8;
            }
        }

        &.impersonated {
            padding-bottom: 150px;
        }
    }
}

.betaTag {
    padding: 2px 7px 1px !important;
    margin-left: 5px;
}

@include laptop {
    .contentWrap {
        .content {
            padding: 30px;
        }
    }
}