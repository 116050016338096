.categoryTitle {
  margin-top: 20px;

  * {
    vertical-align: middle;
  }
}

.categoryContainer {
  margin-top: 10px;
  background-color: var(--color-white);
  padding: 15px 20px;
  border-radius: 10px;
  border: 1px solid var(--color-primary-200);
  width: 450px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    line-height: normal;
  }
}

.withStatusTag {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.categorySubtitle {
  margin-bottom: 5px;
  color: var(--color-primary-500);
  text-transform: uppercase;
  font-size: var(--fs-12);

  * {
    vertical-align: middle;
  }
}

.categoryTag {
  background-color: var(--color-primary-100);
  padding: 0 5px;
  border-radius: 5px;
}

.fees {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: var(--fs-14);
  color: var(--color-primary-500);
}

.price {
  font-weight: 700;
  font-size: var(--fs-18);
  color: var(--color-primary);
}

.actions {
  margin-top: 40px;
  display: flex;
  gap: 20px;
}

.isOwnManager {
  background-color: var(--color-primary-100);
  border-color: var(--color-primary-100);
}

.rent {
  display: flex;
  gap: 15px;
  justify-content: space-between;
}